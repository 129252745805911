import { create } from "zustand";
import { StoreApi, createStore } from "zustand/vanilla";

export type AuthStoreValues = {
  token: string;
  faToken: string;
  isAdmin: boolean;
  isProjectOwner: boolean;
  appId: string;
  apimKey: string;
  scope: string;
  redirectUri: string;
  authority: string;
  serverUrl: string;
  functionAppId: string;
  functionAuthority: string;
  functionUserFlow: string;
  functionRedirectUri: string;
  functionAuthorityDomain: string;
  layoutString: string;
  layoutObject: { name: string; path: string }[];
};

export type AuthStoreActions = {
  setToken: (token: string) => void;
  setFaToken: (faToken: string) => void;
  setIsAdmin: (isAdmin: boolean) => void;
  setIsProjectOwner: (isProjectOwner: boolean) => void;
  setAppId: (appId: string) => void;
  setApimKey: (apimKey: string) => void;
  setScope: (scope: string) => void;
  setRedirectUri: (redirectUri: string) => void;
  setAuthority: (authority: string) => void;
  setServerUrl: (serverUrl: string) => void;
  setFunctionAppId: (functionAppId: string) => void;
  setFunctionAuthority: (functionAuthority: string) => void;
  setFunctionUserFlow: (functionUserFlow: string) => void;
  setFunctionRedirectUri: (functionRedirectUri: string) => void;
  setFunctionAuthorityDomain: (functionAuthorityDomain: string) => void;
  setConfig: (config: Partial<AuthStoreValues>) => void;
  setLayoutString: (layoutString: string) => void;
  setLayoutObject: (layoutObject: { name: string; path: string }[]) => void;
};

export type AuthStore = AuthStoreValues & AuthStoreActions;

const config = (window as any).ReactAppConfig;

export const authStore = createStore<AuthStore>((set) => ({
  token: "",
  isAdmin: false,
  isProjectOwner: false,
  appId: process.env.MSAL_APPID || "",
  scope: process.env.MSAL_SCOPE || "",
  redirectUri: process.env.MSAL_REDIRECTURI || "",
  authority: process.env.MSAL_AUTHORITY || "",
  serverUrl: process.env.FUNCTION_APP_API || config.serverUrl,
  functionAppId: process.env.FUNCTION_APPID || config.functionAppId,
  functionAuthority: process.env.FUNCTION_AUTHORITY || config.functionAuthority,
  functionUserFlow: process.env.FUNCTION_USERFLOW || config.functionUserFlow,
  functionRedirectUri:
    process.env.FUNCTION_REDIRECTURI || config.functionRedirectUri,
  functionAuthorityDomain:
    process.env.FUNCTION_AUTHORITYDOMAIN || config.functionAuthorityDomain,
  layoutString: process.env.LAYOUT_TEMPLATE_STRING || config.layoutString,
  layoutObject: process.env.LAYOUT_OBJECT
    ? (JSON.parse(process.env.LAYOUT_OBJECT) as {
        name: string;
        path: string;
      }[])
    : config.layoutObject
    ? (JSON.parse(config.layoutObject) as { name: string; path: string }[])
    : [],
  faToken: process.env.FADEFAULT || config.faToken,
  apimKey: process.env.apimKey || config.apimKey,
  setToken: (token: string) => set({ token }),
  setIsAdmin: (isAdmin: boolean) => set({ isAdmin }),
  setIsProjectOwner: (isProjectOwner: boolean) => set({ isProjectOwner }),
  setAppId: (appId: string) => set({ appId }),
  setScope: (scope: string) => set({ scope }),
  setRedirectUri: (redirectUri: string) => set({ redirectUri }),
  setAuthority: (authority: string) => set({ authority }),
  setServerUrl: (serverUrl: string) => set({ serverUrl }),
  setFunctionAppId: (functionAppId: string) => set({ functionAppId }),
  setFunctionAuthority: (functionAuthority: string) =>
    set({ functionAuthority }),
  setFunctionUserFlow: (functionUserFlow: string) => set({ functionUserFlow }),
  setFunctionRedirectUri: (functionRedirectUri: string) =>
    set({ functionRedirectUri }),
  setFunctionAuthorityDomain: (functionAuthorityDomain: string) =>
    set({ functionAuthorityDomain }),
  setFaToken: (faToken: string) => set({ faToken }),
  setApimKey: (apimKey: string) => set({ apimKey }),
  setConfig: (config: Partial<AuthStoreValues>) =>
    set((state) => ({ ...state, ...config })),
  setLayoutString: (layoutString: string) => set({ layoutString }),
  setLayoutObject: (layoutObject: { name: string; path: string }[]) =>
    set({ layoutObject }),
}));

export type AccountPanelStoreValues = {
  showAccountPanel: boolean;
};

export type AccountPanelStoreActions = {
  setShowAccountPanel: (showAccountPanel: boolean) => void;
};

export type AccountPanelStore = AccountPanelStoreValues &
  AccountPanelStoreActions;

export const accountPanelStore = createStore<AccountPanelStore>((set) => ({
  showAccountPanel: false,
  setShowAccountPanel: (showAccountPanel: boolean) => set({ showAccountPanel }),
}));

export const useZStore = <T extends any>(store: StoreApi<T>) => create(store);
